
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";

export default defineComponent({
  name: "add_type",
  components: {},
  props: {
    data: { type: Object },
  },
  setup() {
    const formData = ref({
      id: "",
      name: "",
      entity_type_level: "",
      description: "",
      addModal: "add"
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const addModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const update = ref<boolean>(false);
    const rules = ref({
      id: [
        {
          required: false,
          message: "ID is not required",
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      entity_type_level: [
        {
          required: true,
          message: "Type Level is required",
          trigger: "change",
        },
      ],
      description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",
        },
      ],
    });
    const { bus } = useBus();

    bus.on("edit-modal-data", (data) => {
      update.value = true;
      formData.value = data;
    });

    bus.on("add-modal-data", () => {
      update.value = false;
      formData.value = {
        id: "",
        name: "",
        entity_type_level: "",
        description: "",
        addModal: "add"
      };
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          const action = "post";
          const url = "configurations/entity_type/save";

          await ApiService[action](url, formData.value)
            .then((response) => {
              loading.value = false;
              bus.emit("entity-updated", true);
              if (response.status == 200) {
                hideModal(addModalRef.value);
                Swal.fire({
                  title: "Success!",
                  text: response.data.data,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(addModalRef.value);
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              loading.value = false;
              console.log(response);
            });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addModalRef,
    };
  },
});
